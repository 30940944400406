<template>
  <div class="public Intelligent">
    <el-container>
      <el-container>
        <el-main>
          <el-form :inline="true" :model="formInline" class="form-inline" label-width="110px">
            <el-row>
              <el-form-item label="经营者类型：" >
                <el-select v-model="formInline.hairpinType" placeholder="公司性质" class="winput">
                  <el-option label="全部" value=""></el-option>
                  <el-option v-for="item in cardTypeOptions" :key="item.haripinType" :label="item.name"
                             :value="item.haripinType"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="领域行业：">
                <el-select v-model="formInline.industry" placeholder="领域" @change="changeIndustry" class="mright">
                  <el-option label="全部领域" value=""></el-option>
                  <el-option v-for="item in industryOptions" :key="item.code" :label="item.name"
                             :value="item.code"></el-option>
                </el-select>
                <el-select v-model="formInline.industryId" placeholder="行业">
                  <el-option label="全部行业" value=""></el-option>
                  <el-option v-for="item in subIndustryOptions" :key="item.code" :label="item.name"
                             :value="item.code"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="地区：">
                <el-cascader class="cascaders-content" ref="cascaders" :props="props" :options="options"
                             v-model="cascaders" @change="areaChange">
                  <template slot-scope="{ node, data }">
                    <div @click="nodeFocus">{{ data.label }}</div>
                  </template>
                </el-cascader>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="经营者名称：">
                <el-input placeholder="请输入经营者名称" v-model="formInline.content" size="small" clearable class="search-box">
                </el-input>
              </el-form-item>
              <el-form-item >
                <el-button class="search-btn" @click="tableSearch">搜 索</el-button>
              </el-form-item>
            </el-row>
          </el-form>
          <div class="default-table">
            <el-table v-loading="loading" :data="tableData" style="width: 100%">
              <el-table-column prop="busName" label="经营者名称" width="300"></el-table-column>
              <el-table-column prop="safeCodeImg" label="预付码" width="80">
                <template slot-scope="scope">
                  <img :src="baseUrl+scope.row.safeCodeImg" style="width:60px;height:60px;"/>
                </template>
              </el-table-column>
              <el-table-column prop="location" label="所属地区"></el-table-column>
              <el-table-column prop="industry" label="所属行业"></el-table-column>
              <el-table-column prop="fundMode" label="监管方式"></el-table-column>
              <el-table-column prop="hairpinType" label="经营者类型"></el-table-column>
              <el-table-column label="查看" width="80">
                <template slot-scope="scope">
                  <el-button type="text" @click="goPage(scope.row.riskBusId,scope.row)">详情</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="page">
              <el-pagination background layout="total, prev, pager, next" prev-text="上一页" next-text="下一页"
                             :current-page="currentPage"
                             :page-size="pageSize"
                             :total="total"
                             @size-change="handleSizeChange"
                             @current-change="handleCurrentChange">
              </el-pagination>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script type="text/javascript">
import {baseURL} from "@/api/config"
import {cityList, cardType} from "@/api/common";
import {industry, search} from "@/api/intelligent";
import {clone} from "lodash"

export default {
  name: "Intelligent",
  data() {
    let _this = this;
    return {
      initCitys: {
        provinceId: '',
        cityId: '',
        areaId: '',
      },
      labels: {
        pLabel: "",
        cLabel: "",
        aLabel: "",
        pid: '',
        cid: '',
        did: '',
      },
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
      industryOptions: [],
      subIndustryOptions: [],
      cardTypeOptions: [],
      formInline: {
        regionType: '0',
        regionName: '全国',
        regionId: 1,
        flag: 1,
        hairpinType: '',
        industry: '',
        industryId: '',
        content: ''
      },
      total: 0,
      pageSize: 10,
      currentPage: 1,
      tableData: [],
      baseUrl: '',
      cascaders: [],
      paramLen: 0,
      isSearch: false,
      loading:false,
      props: {
        emitPath: true,
        lazy: true, //动态加载
        checkStrictly: true, //父子节点取消选中关联，从而达到选择任意一级选项
        value: "regionId",
        label: "regionName",
        disabled: "isDisabled",
        lazyLoad(node, resolve) {
          // console.log(node)
          _this.paramLen++;
          let id = 1;
          if (node.data && node.data.regionId) {
            id = node.data.regionId
          }
          if (node.label === '乡镇街道') {
            resolve()
          } else if (node.label === '商圈') {
            resolve()
          } else if (node.label === '综合体') {
            resolve()
          } else {
            _this.cityList(id, node, (list) => {
              resolve(list)
              console.log("_this.isSearch", _this.isSearch)
              if (_this.$route.query.provinceId && (_this.paramLen - 1 === _this.cascaders.length) && _this.cascaders.length <= 3) {
                _this.isSearch = true;
                console.log(_this.cascaders.length)
                _this.areaChange(_this.cascaders)
              }
            });
          }
        }
      },
      options: [],
      parentNode: '',
    };
  },
  created() {
    let query = this.$route.query;
    if (query && query.provinceId) {
      this.disabled = true;
      if (query.provinceId == '0') {
        query.provinceId = "";
      } else {
        this.cascaders[0] = query.provinceId - 0;
      }
      if (query.cityId == '0') {
        query.cityId = ""
      } else {
        this.cascaders[1] = query.cityId - 0;
      }
      if (query.areaId == '0') {
        query.areaId = ""
      } else {
        this.cascaders[2] = query.areaId - 0;
      }

      window.sessionStorage.setItem('initCitys', JSON.stringify(query))
      // this.initCitys = JSON.parse(window.sessionStorage.getItem('initCitys'))
      this.initCitys = query
      console.log(query)
      this.disabled = true;
      if (this.initCitys.provinceId || this.initCitys.cityId || this.initCitys.areaId) {
        // this.cascaders = [this.initCitys.provinceId - 0,this.initCitys.cityId - 0,this.initCitys.areaId - 0];
        console.log(this.cascaders)
        this.intData();
      }
    } else {
      window.sessionStorage.setItem("initCitys", "{}")
      this.cascaders = [1];
      this.intData();
      this.tableSearch()

    }
  },
  mounted() {
    this.baseUrl = baseURL
    // this.intData();
  },
  watch: {
    $route: {
      handler(val, oldVal) {
        console.log("val=====", val)
      },
      deep: true, //增加deep 观察对象的子对象变化
      immediate: true,
    },
  },

  methods: {
    intData(initCity) {
      // this.cityList()
      this.industryList()
      this.cardType()
      // if(!initCity){
      //   this.tableSearch()
      // }

    },
    cityList(id, node, successFn) {
      cityList({
        regionId: id,
      }).then((res) => {
        let zht = [];
        let street = [];
        let sq = [];
        let index = 0;
        res.data.nodeList.map((val, ind) => {
          val.leaf = false;
          val.value = val.regionId;
          val.label = val.regionName;
          val.flag = 1;
          val.isDisabled = this.initCitys.provinceId && (val.regionType === 0 || val.regionType === 1) ?
              this.disabled : this.initCitys.cityId && val.regionType === 2 ? this.disabled :
                  this.initCitys.areaId && val.regionType === 3 ? this.disabled : false;

          //如果带默认参数，参数的同级级设为叶子节点，下级不是叶子节点，参数对应节点不是叶子节点且最后一级不禁用。
          if (this.$route.query && this.initCitys.provinceId) {
            val.leaf = true;
            if (this.initCitys.provinceId && this.initCitys.cityId && this.initCitys.areaId) {
              if (val.regionType > 3) {
                val.leaf = false;
              }
              if (this.initCitys.areaId == val.regionId) {
                val.isDisabled = false
              }
            } else if (this.initCitys.provinceId && this.initCitys.cityId) {
              if (val.regionType > 2) {
                val.leaf = false;
              }
              if (this.initCitys.cityId == val.regionId) {
                val.isDisabled = false
              }
            } else if (this.initCitys.provinceId) {
              if (val.regionType > 1) {
                val.leaf = false;
              }
              if (this.initCitys.areaId == val.regionId) {
                val.isDisabled = false
              }
            }
          }
          if (val.regionId == this.initCitys.provinceId || val.regionId == this.initCitys.cityId || val.regionId == this.initCitys.areaId) {
            index = ind
            val.leaf = false;
          }

          // val.disabled = disabled;
          if (val.regionType === 4) {
            val.leaf = false;
            street.push(val)
          } else if (val.regionType === 6) {
            val.leaf = true;
            zht.push(val)
          } else if (val.regionType === 8) {
            val.leaf = true;
            sq.push(val)
          } else if (val.regionType === 7) {
            val.leaf = true;
          }
          return val
        })
        let arrs = [];
        if (id === 1) {
          let option = [];
          option.push({
            regionType: '0',
            flag: 1,
            regionId: 1,
            value: 1,
            label: "全国",
            regionName: "全国",
            leaf: true,
            isDisabled: this.disabled
          })
          arrs = option.concat(res.data.nodeList)
        } else if (id !== 1 && node) {
          if (res.data.nodeList.length) {
            if (street.length || zht.length || sq.length) {
              arrs = [{
                regionId: id + '-4',
                value: id + '-4',
                label: "乡镇街道",
                regionName: "乡镇街道",
                regionType: 4,
                flag: 4,
                leaf: street.length ? false : true,
                children: street,
              }, {
                regionId: id + '-6',
                regionName: "综合体",
                value: id + '-6',
                label: "综合体",
                regionType: 6,
                flag: 6,
                leaf: zht.length ? false : true,
                children: zht,
              }, {
                regionId: id + '-8',
                regionName: "商圈",
                value: id + '-8',
                label: "商圈",
                regionType: 8,
                flag: 8,
                leaf: sq.length ? false : true,
                children: sq,
              }]
            } else {
              arrs = res.data.nodeList;
            }
          } else {
            node.leaf = true;
          }
        }

        if (successFn) {
          successFn(arrs, index)
        }
      })

    },
    areaChange(val) {

      this.$nextTick(() => {
        let emptyDom = document.getElementsByClassName("el-scrollbar__view el-cascader-menu__list is-empty")[0];

        if (emptyDom) {
          this.parentNode = emptyDom.parentNode.parentNode
          this.parentNode.style.display = 'none'
        } else if (this.parentNode) {
          this.parentNode.style.display = 'block'
        }
        setTimeout(() => {
          let input = this.$refs.cascaders.$children[0].$refs.input;
          input.style.width = 13 * (input.value.length - (val.length - 1) * 2) + 47 + 'px';
        }, 80)

      })

      let node = clone(this.$refs.cascaders.getCheckedNodes()[0])
      let regionId = val[val.length - 1];
      if (node.data.flag == 4 || node.data.flag == 6 || node.data.flag == 8) {
        regionId = node.value.split("-")[0] - 0;
      }
      let formInlinePrev = clone(this.formInline)
      this.formInline.regionType = node.data.regionType;
      this.formInline.flag = node.data.flag;
      this.formInline.regionId = regionId;
      this.formInline.regionName = node.data.regionName;

      console.log('formInlinePrev', formInlinePrev)
      console.log('formInline', this.formInline)
      //组件的值变化后关闭下拉框，默认带参数执行一次search
      if ((formInlinePrev.regionId !== this.formInline.regionId) || (formInlinePrev.regionName !== this.formInline.regionName)) {
        setTimeout(() => {
          this.$refs.cascaders.dropDownVisible = false;
        }, 300)
        console.log('isSearch', this.isSearch)
        if (this.isSearch) {
          this.tableSearch()
        }
      }
      this.isSearch = false;

    },
    nodeFocus() {
      if (this.parentNode) {
        this.parentNode.style.display = 'block'
      }
    },
    industryList() {
      industry().then((res) => {
        this.industryOptions = res.data;
      })
    },
    changeIndustry(val) {
      this.formInline.industryId = ''
      this.subIndustryOptions = []
      if (val != "") {
        industry({
          industryId: val
        }).then((res) => {
          this.subIndustryOptions = res.data;
        })
      }
    },
    cardType() {
      cardType().then(res => {
        this.cardTypeOptions = res.data
      })
    },
    tableSearch() {
      this.tableData = [];
      this.loading = true;
      let params = {
        regionType: this.formInline.regionType == "" ? undefined : this.formInline.regionType,
        regionId: this.formInline.regionId == "" ? undefined : this.formInline.regionId,
        flag: this.formInline.flag == "" ? undefined : this.formInline.flag,
        hairpinType: this.formInline.hairpinType == "" ? undefined : this.formInline.hairpinType,
        industryId: this.formInline.industryId !== "" ? this.formInline.industryId : this.formInline.industry == "" ? undefined : this.formInline.industry,
        content: this.formInline.content == "" ? undefined : this.formInline.content,
      }
      search({
        limit: this.pageSize,
        page: this.currentPage,
        ...params
      }).then((res) => {
        this.loading = false;
        this.tableData = res.data.list || []
        this.total = res.data.totalCount
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.tableSearch()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.tableSearch()
    },
    goPage(val, row) {
      console.log(this.labels)

      localStorage.setItem("rowData", JSON.stringify(row))
      window.sessionStorage.setItem("labels", JSON.stringify(this.labels))
      this.$router.push({path: '/IntelligentAnalysis', query: {riskBusId: val}})
    }
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.Intelligent {
  .left-box {
    width: 220px;
    background: url("../assets/imgs/insight-bg.jpg") no-repeat;
    padding-top: 10px;
  }

  .form-inline {
    margin-top: 20px;
    .el-form-item{
      margin-right: 0px;
    }

    &::v-deep .el-form-item__label {
      color: #fff;
    }

    .mright{
      margin-right: 15px;
    }
    .el-select {
      width: 160px;
    }
    .search-btn{
      margin-left: 110px;
      background-color: #3a8ed2;
      color: #fff;
      border-color: #3a8ed2;
      font-size: 15px;
      border-radius: 5px;
      padding: 8px 40px;
    }

    .search-box {
      width: 160px;
    }

    label {
      font-size: 12px;
    }

    .cascaders-content {
      &::v-deep .el-input__inner {
        min-width: 120px;
        width: 120px;
      }
    }
  }
}
</style>
